<script>
  import TextHighlight from 'vue-text-highlight';

  export default {
    name: 'text-highlighter',
    functional: true,

    props: {
      highlightClass: String,
      query: [String, Array],
    },

    render: (h, context) => {
      const { highlightClass = 'text--highlight', query } = context.props;
      const highlightStyle = '';
      const highlightComponent = 'span';
      return h(
        TextHighlight,
        { props: { queries: query, highlightClass, highlightStyle, highlightComponent } },
        context.scopedSlots.default()
      );
    },
  };
</script>
