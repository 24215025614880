<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="filters-dialog"
  fullscreen
  hide-overlay
  no-click-animation
  persistent
  transition="dialog-bottom-transition"
)
  edit-filter-base-dialog.search-dialog(
    title="Поиск"
    :confirm-text="buttonText"
    from-search-dialog
    :resetable="!!query"
    @click:close="clickClose"
    @click:confirm="clickConfirm"
    @click:reset="clickReset"
    :loading="loading"
  )
    template(#toolbar)
      .search-dialog-toolbar.px-4.d-flex.align-center
        ui-search-text-field.search-dialog-toolbar__input(
          ref="query_input"
          :value="query"
          placeholder="Поиск по каталогу"
          autofocus
          :clearable="Boolean(!!query && query.length > 0)"
          @click:clear="clickReset"
          @input="query = $event; debouncedSearch($event)"
          :loading="loading"
          @keyup.enter="clickConfirm"
        )
        ui-btn.ml-2(
          primary
          width="70px"
          @click="clickConfirm"
          ) Найти

    template(#default)
      .search-dialog__content
        template(v-if="!!query && !loading")
          template(v-if="foundCategories.length > 0")
            v-list.search-dialog-list
              v-subheader.t-caption-small.c-gray60 Каталог
              v-list-item.search-dialog-item(
                v-for="(category, index) in foundCategories"
                :key="`category-suggestion${index}`"
                two-line
                :to="$path.catalog(category.class_name, purpose)"
              )
                v-list-item-content
                  v-list-item-title.search-dialog-item__title
                    text-highlighter(:query="query") {{ category.title }}
                    span.t-xsmall.c-gray60.ml-2 {{ category.total }}
                  v-list-item-subtitle.search-dialog-item__desc {{ topmostCategory(category) }}

          template(v-if="foundAssets.length > 0")
            v-list.search-dialog-list
              v-subheader.t-caption-small.c-gray60 Активы
              v-list-item.search-dialog-item(
                v-for="(asset, index) in foundAssets"
                :key="`asset-suggestion${index}`"
                two-line
                :to="$path.asset(asset.short_id)"
              )
                v-list-item-content
                  v-list-item-title.search-dialog-item__title
                    text-highlighter(:query="query") {{ asset.title }}
                  v-list-item-subtitle.search-dialog-item__desc {{ asset.address }}
                v-list-item-action.mt-0
                  v-list-item-action-text.t-caption-middle-bold.c-gray80 {{ asset.price }}

        info-panel.mx-4.mt-6(
          v-if="!!query && !loading && foundCategories.length === 0 && foundAssets.length === 0"
        ) По вашему запросу не найдено совпадений
</template>

<script>
  import { UiLoader, UiSearchTextField, UiBtn } from '@/uikit';
  import InfoPanel from '@/components/search/InfoPanel.vue';
  import EditFilterBaseDialog from '@/components/search/EditFilterBaseDialog.vue';
  import TextHighlighter from '@/uikit/components/functional/TextHighlighter.vue';
  import debounce from 'underscore/modules/debounce.js';

  import { service as API } from '@/services/assets/assets-service';
  import { CategoriesTree } from '@/services/assets/categories-tree';
  import { formatAssetsCount } from '@/uikit/filters/pluralize';
  import { createLogger } from '@/uikit/util';
  import { handleError } from '@/libs/handle-error';
  const logger = createLogger('SearchBarQuery', 'cyan');

  export default {
    name: 'search-dialog',
    components: {
      EditFilterBaseDialog,
      InfoPanel,
      TextHighlighter,
      UiLoader,
      UiSearchTextField,
      UiBtn,
    },

    props: {
      purpose: { type: String, default: 'buy' },
      categoriesTreeObject: { type: Object, default: undefined },
    },

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        categories: [],
        assets: [],
        assetsTotal: undefined,
        categoriesTree: this.categoriesTreeObject,
        loading: false,
        hasResults: false,
        query: undefined,
      };
    },

    computed: {
      foundCategories() {
        const categories = this.categories;
        return categories.splice(0, 3);
      },

      foundAssets() {
        const assets = this.assets;
        return assets.splice(0, 3);
      },

      buttonText() {
        return !this.query || (this.query && !this.assetsTotal)
          ? 'Найти'
          : `Показать ${formatAssetsCount(this.assetsTotal)}`;
      },
    },

    watch: {
      purpose(newValue) {
        this.fetchCategories(newValue);
      },
    },

    created() {
      this.debouncedSearch = debounce(this.search, 400);
    },

    methods: {
      open(query = undefined) {
        this.query = query;
        this.isActive = true;
        this.$nextTick(() => {
          this.$refs.query_input.$el.focus();
        });

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      topmostCategory(category) {
        return category.parents.length === 0
          ? 'Каталог'
          : this.categoriesTree.get(category.parents[0])?.title;
      },

      async search(query) {
        if (this.loading || !query || query.length < 3) return;

        try {
          this.loading = true;
          logger.log('Ищем совпадения:', query);

          await Promise.all([this.searchAssets(query), this.searchCategories(query)]);
          this.loading = false;
          this.hasResults =
            Boolean(this.assets && this.assets.length > 0) ||
            Boolean(this.categories && this.categories.length > 0);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      async searchAssets(query) {
        logger.log('Ищем совпадения по активам:', query);
        const { assets, total } = await API.findAssets({ query: query }, this.purpose);
        this.assetsTotal = total;
        this.assets = assets && assets.length > 0 ? assets : [];
      },

      async searchCategories(query) {
        logger.log('Ищем совпадения по категориям:', query);

        if (!this.categoriesTree) {
          this.categoriesTree = await this.fetchCategories();
        }

        logger.log('Поиск по индексу категорий', this.categoriesTree);
        const found = this.categoriesTree.searchByTitle(query);
        this.categories = found.sort((c1, c2) => c1.level - c2.level);
      },

      async fetchCategories() {
        try {
          logger.log('Запрашиваем список категорий и строим дерево');
          const categories = await API.getCategories(this.purpose);
          return new CategoriesTree(categories);
        } catch (error) {
          logger.error(error);
        }
      },

      clickClose() {
        this.resolve(false);
        this.isActive = false;
      },

      clickConfirm() {
        this.resolve(this.query);
        this.isActive = false;
      },

      clickReset() {
        this.query = undefined;
        this.categories = [];
        this.assets = [];
        this.assetsTotal = undefined;
        this.hasResults = false;
      },
    },
  };
</script>

<style lang="scss">
  .search-dialog {
    .search-dialog-list {
    }

    .search-dialog-item {
      width: 100%;
      max-width: 100%;

      .search-dialog-item__title {
        @include text-style('caption-normal', 'light');
        color: c('gray80', 'light') !important;
      }

      .search-dialog-item__desc {
        @include text-style('caption-small', 'light');
        margin-top: 2px;
        color: c('gray60', 'light') !important;
      }
    }

    .text__highlight.text--highlight {
      background-color: c('primary20', 'light');
    }
  }
  .search-dialog-toolbar {

    .theme--light.v-text-field.text-field--search {
      .v-input__control .v-input__slot {
        background-color: transparent !important;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid c('gray40', 'light');
      }

      &.v-input--is-focused .v-input__control .v-input__slot {
        border-bottom: 2px solid c('primary60', 'light');
        box-shadow: none !important;
      }
    }
  }

</style>
